import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import axios from "axios";
import * as React from "react";
import { useState } from "react";
import { CiCircleCheck } from "react-icons/ci";
import { FaWpforms } from "react-icons/fa";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FcProcess } from "react-icons/fc";
import { FiUpload } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import CardInit from "../../../components/CardInit/CardInit";
import CenteredCircularProgress from "../../../components/CenteredCircularProgress";
import Footer from "../../../components/Footer";
import HeaderHome from "../../../components/Header";
import "./style.css";
import { analysisAtom } from "../../../utils/Atoms/analysisAtom";
import { useAtom } from "jotai";

require("../../tokenRenewal");

const CardStyled = styled(Card)({
  margin: 15,
  boxShadow: "0 0 1em #9A9A9A",
  // padding: 5,
  height: 110,
  width: 110,
  transition: "transform .2s" /* Animation */,

  "&:hover": {
    transform: "scale(1.2)",
  },
});

function HomeRegistration(props) {
  const [form, setForm] = useState([]);
  const [analysis, setAnalysis] = useAtom(analysisAtom);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchData = async () => {
      let id;
      if (localStorage.getItem("dataCnpjId") === null) {
        navigate("/select-proposal");
      }
      id = localStorage.getItem("dataCnpjId");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/analysisbycnpj/${id}`,
          {
            headers: {
              Authorization: props.token,
              "Content-Type": "application/json",
            },
          }
        );
        localStorage.setItem("analysis", JSON.stringify(response.data));
        setAnalysis(response.data);
        const analysisStatusForm = response.data?.statusForm;
        props.setStatusForm(analysisStatusForm);
        const analysisStatusUpload = response.data?.statusUpload;
        props.setStatusUpload(analysisStatusUpload);
        const analysisStatusRisk = response.data?.statusRisk;
        props.setStatusRisk(analysisStatusRisk);
      } catch (error) {
        console.error("Erro ao buscar análise", error);
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    const analysisStatusForm = analysis?.statusForm;
    props.setStatusForm(analysisStatusForm);
    const analysisStatusUpload = analysis?.statusUpload;
    props.setStatusUpload(analysisStatusUpload);
    const analysisStatusRisk = analysis?.statusRisk;
    props.setStatusRisk(analysisStatusRisk);
  }, [analysis]);

  const statusRisk = analysis?.statusRisk;
  const statusCompliance = analysis?.statusCompliance
    ? analysis?.statusCompliance
    : null;

  let message;
  const renderStatus = (status, message) => (
    <div className={`statusBox ${status}`}>
      {status === "statusAprovado" ? <CiCircleCheck /> : <FcProcess />}
      <p>Status do processo: {message}</p>
    </div>
  );

  const renderAnalysisMessage = () => (
    <>{renderStatus("status", "em análise.")}</>
  );

  const renderApprovedMessage = () => (
    <>{renderStatus("statusAprovado", "proposta aprovada!")}</>
  );

  const equalsIgnoreCase = (str1, str2) => {
    if (typeof str1 === "string" && typeof str2 === "string") {
      return str1.toLowerCase() === str2.toLowerCase();
    }
    return false;
  };

  if (analysis?.clientType === "PSP") {
    if (equalsIgnoreCase(statusRisk, "EM ANALISE")) {
      message = renderAnalysisMessage();
    } else if (
      equalsIgnoreCase(statusRisk, "APROVADO") &&
      statusCompliance === null
    ) {
      message = renderAnalysisMessage();
    } else if (
      equalsIgnoreCase(statusRisk, "APROVADO") &&
      equalsIgnoreCase(statusCompliance, "APROVADO")
    ) {
      message = renderApprovedMessage();
    }
  } else {
    if (equalsIgnoreCase(statusRisk, "EM ANALISE")) {
      message = renderAnalysisMessage();
    } else if (equalsIgnoreCase(statusRisk, "APROVADO")) {
      message = renderApprovedMessage();
    }
  }

  return (
    <>
      <HeaderHome token={props.token} statusRisk={statusRisk} />
      <div className="homeContainer">
        {loading && <CenteredCircularProgress />}
        {!loading && (
          <>
            <div className="title">
              <h2>Realize as etapas de cadastro abaixo para continuarmos.</h2>
            </div>
            <div className="cardsContainer">
              <div className="cards">
                <CardInit
                  title={"1. Formulário"}
                  description={
                    "Preencha todos os dados requisitados no formulário."
                  }
                  icon={<FaWpforms />}
                  onClick={() => {
                    navigate("/form");
                  }}
                  className={
                    analysis?.statusForm === "COMPLETO" ? "blocked" : ""
                  }
                />

                <CardInit
                  title={"2. Upload dos documentos"}
                  description={
                    "Realize o upload dos documentos empresariais e societários."
                  }
                  icon={<FiUpload />}
                  onClick={() => {
                    navigate("/uploads");
                  }}
                  className={
                    analysis?.statusUpload === "COMPLETO" ? "blocked" : ""
                  }
                />

                <CardInit
                  title={"3. Upload do documento Forecast"}
                  description={"Realize o upload do documento de Forecast."}
                  icon={<FaMoneyBillTrendUp />}
                  onClick={() => {
                    navigate("/forecast");
                  }}
                  className={
                    analysis?.statusRisk?.toUpperCase() === "APROVADO" ||
                    analysis?.statusRisk?.toUpperCase() === "REJEITADO" ||
                    analysis?.statusRisk?.toUpperCase() === "REPROVADO"
                      ? "blocked"
                      : ""
                  }
                />
              </div>
            </div>

            <Grid className="statusHome" item xs={2}>
              {message}
            </Grid>
            <Footer />
          </>
        )}
      </div>
    </>
  );
}
export default HomeRegistration;

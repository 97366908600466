import React, { createContext, useContext, useState, useEffect } from "react";
import {
  getStatusRisk,
  setStatusRisk as persistStatusRisk,
} from "../utils/statusRisk";
import { analysisAtom } from "../utils/Atoms/analysisAtom";
import { useAtom } from "jotai";

const StatusRiskContext = createContext();

export const useStatusRisk = () => useContext(StatusRiskContext);

export const StatusRiskProvider = ({ children }) => {
  const [statusRisk, setStatusRiskState] = useState(getStatusRisk);
  const [analysis, setAnalysis] = useAtom(analysisAtom);

  const setStatusRisk = (statusRisk) => {
    setStatusRiskState(statusRisk);
    persistStatusRisk(statusRisk);
  };

  useEffect(() => {
    const newStatusRisk = analysis?.statusRisk;
    setStatusRiskState(newStatusRisk);
  }, [analysis]);

  return (
    <StatusRiskContext.Provider value={{ statusRisk, setStatusRisk }}>
      {children}
    </StatusRiskContext.Provider>
  );
};

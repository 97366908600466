import React from "react";
import "./style.css";
import { formatCnpj } from "../../utils/formattedTexts";

const CardCnpj = ({
  icon,
  title,
  cnpj,
  onClick,
  actionName,
  className,
  date,
  status,
}) => {
  function formatDate(dateData) {
    const date = new Date(dateData);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }
  return (
    <div className={`CardCnpjContainer ${className}`} onClick={onClick}>
      <div className="cardTitleAndIcon">
        <div className="cardIcon flex">{icon}</div>
        <h3>{title}</h3>
      </div>
      <div>
        <p>
          <strong className={`${status.replace(/\s+/g, "").toLowerCase()}`}>
            {status === "APROVADO"
              ? "Proposta aprovada"
              : "Proposta em análise"}
          </strong>
        </p>
        <p>
          <strong>CNPJ: </strong>
          {formatCnpj(cnpj)}
        </p>
        <p>
          <strong>Data de criação: </strong>
          {formatDate(date)}
        </p>
      </div>

      <button>{actionName ? actionName : "Acessar"}</button>
    </div>
  );
};

export default CardCnpj;

import React, { createContext, useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";

const PartnersContext = createContext();

export const PartnersProvider = ({ children }) => {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const fetchAndSetPartners = () => {
      try {
        const analysisData = JSON.parse(localStorage.getItem("analysis"));
        if (analysisData) {
          const neowayDataString = analysisData?.neowayData?.neowayData?.neowayData;
          const bigDataString = analysisData?.bigData?.bigData?.responses?.[0]?.Result?.[0]?.Relationships?.CurrentRelationships;

          let neowayDataParsed = null;

          if (neowayDataString) {
            neowayDataParsed = JSON.parse(neowayDataString);
          }

          const newPartners = neowayDataParsed?.socios;

          if (newPartners) {
            const newObj = newPartners?.map((partner) => ({
              fullName: partner.nome,
              cpf: partner.documento,
            }));
            setPartners(newObj);
          } else if (bigDataString) {
            const newObj = bigDataString.map((partner) => ({
              fullName: partner.RelatedEntityName,
              cpf: partner.RelatedEntityTaxIdNumber,
            }));
            setPartners(newObj);
          } else {
            console.log("Neither neowayData nor bigDataString found.");
          }
        }else{
          console.error("Erro ao recuperar dados. Por favor, recarregue a página.")
        }
      } catch (error) {
        console.error("Error parsing JSON data:", error);
        // toast.error("Erro ao recuperar dados. Por favor, recarregue a página. Se o erro persistir, entre em contato.")
      }
    };

    fetchAndSetPartners();
  }, []);

  return (
    <PartnersContext.Provider value={{ partners }}>
      {children}
    </PartnersContext.Provider>
  );
};

export const usePartners = () => {
  return useContext(PartnersContext);
};

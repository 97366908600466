import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CardCnpj from "../../../components/CardCnpj/CardCnpj";
import CenteredCircularProgress from "../../../components/CenteredCircularProgress";
import Footer from "../../../components/Footer";
import HeaderHome from "../../../components/Header";
import { analysisAtom } from "../../../utils/Atoms/analysisAtom";
import { useAtom } from "jotai";

const SelectProposal = (props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [proposals, setProposals] = useState([]);
  const [analysis, setAnalysis] = useAtom(analysisAtom);

  const handleCardClick = async (id) => {
    try {
      localStorage.setItem("dataCnpjId", id);

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/analysisbycnpj/${id}`,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "application/json",
          },
        }
      );
      localStorage.setItem("analysis", JSON.stringify(response.data));
      setAnalysis(response.data);

      navigate("/homeregistration");
    } catch (error) {
      console.error("Erro ao buscar dados da análise:", error);
    }
  };

  React.useEffect(() => {
    if (analysis) {
      const analysisStatusForm = analysis?.statusForm;
      props.setStatusForm(analysisStatusForm);
      const analysisStatusUpload = analysis?.statusUpload;
      props.setStatusUpload(analysisStatusUpload);
      const analysisStatusRisk = analysis?.statusRisk;
      props.setStatusRisk(analysisStatusRisk);
    }
  }, [analysis]);

  const getStatus = useCallback(async () => {
    setLoading(true);
    try {
      const email = localStorage.getItem("email");
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/analysisproposalsbyemail/" + email,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "application/json",
          },
        }
      );
      setProposals(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [props.token, navigate]);

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <>
      <HeaderHome />
      {loading && <CenteredCircularProgress />}
      {!loading && (
        <section className="select-proposal-container">
          <h2>Seja bem-vindo(a) ao Portal de Clientes Entrepay!</h2>
          <p>Selecione uma das propostas abaixo:</p>
          <div className="cards">
            {proposals.map((item) => (
              <CardCnpj
                key={item.id}
                icon={<FaEnvelopeOpenText />}
                title={item.name}
                cnpj={item.cnpj}
                date={item.createdate}
                onClick={() => handleCardClick(item.id)}
                status={item.finalStatus}
              />
            ))}
          </div>
          <Footer />
        </section>
      )}
    </>
  );
};

export default SelectProposal;

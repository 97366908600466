import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { initializeApp } from "firebase/app";
import {
  browserLocalPersistence,
  getAuth,
  setPersistence,
} from "firebase/auth";
import "react-toastify/dist/ReactToastify.css";
import Forecast from "./pages/Register/Forecast/ForecastUpload";
import LegalForm from "./pages/Register/Form/LegalForm";
import React, { cloneElement, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import CenteredCircularProgress from "./components/CenteredCircularProgress";
import Header from "./components/Header";
import { ErrorProvider } from "./context/errorContext";
import { PartnersProvider } from "./context/partnerContext";
import { StatusFormProvider, useStatusForm } from "./context/statusFormContext";
import { StatusUploadProvider, useStatusUpload } from "./context/statusUploadContext";
import "./index.css";
import Form from "./pages/Register/Form/FormPspVar";
import SaveQuestions from "./pages/Register/Form/hooks/SaveQuestions";
import HomeRegistration from "./pages/Register/Home/HomeRegistration";
import SelectProposal from "./pages/Register/Home/SelectProposal";
import Signin from "./pages/Register/SignIn";
import Signup from "./pages/Register/SignUp";
import Uploads from "./pages/Register/Uploads/Uploads";
import Terms from "./pages/Terms/Terms";
import TermsComplete from "./pages/Terms/TermsComplete";
import './utils/i18n';
import  ForecastUpload  from "./pages/Register/Forecast/ForecastUpload";
import { StatusRiskProvider, useStatusRisk } from "./context/statusRiskContext";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.captureConsoleIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/entrepay\.com\.br/],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  release: process.env.REACT_APP_RELEASE,
  environment: process.env.REACT_APP_NODE_ENV,
});

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

setPersistence(auth, browserLocalPersistence);

const PrivateRoute = ({ element: Element }) => {
  const [authenticated, setAuthenticated] = useState(true);
  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { statusForm } = useStatusForm(); 
  const { statusUpload } = useStatusUpload(); 
  const { statusRisk } = useStatusRisk(); 
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoading(true);
      if (user) {
        user.getIdToken().then((token) => {
          setAuthenticated(true);
          setToken(token);
          setIsLoading(false);
        });
      } else {
        setAuthenticated(false);
        setIsLoading(false);
        localStorage.setItem("lastPage", location.pathname);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [location.pathname]);

  if (isLoading) {
    return <CenteredCircularProgress />;
  }

  if (Element.type === Form && statusForm === "COMPLETO") {
    return <Navigate to="/homeRegistration" />; 
  }
  if (Element.type === Uploads && statusUpload === "COMPLETO") {
    return <Navigate to="/homeRegistration" />; 
  }

   if (
    Element.type === ForecastUpload && 
    (
        statusRisk?.toUpperCase() === "APROVADO" || 
        statusRisk?.toUpperCase() === "REJEITADO" || 
        statusRisk?.toUpperCase() === "REPROVADO"
    )
) {
    return <Navigate to="/homeRegistration" />; 
  }
  return authenticated ? (
    cloneElement(Element, { token: token })
  ) : (
    <Navigate to="/signin" />
  );
};

const App = () => {
  const queryClient = new QueryClient();
  const { statusForm, setStatusForm } = useStatusForm();
  const { statusUpload, setStatusUpload } = useStatusUpload();
  const { statusRisk, setStatusRisk } = useStatusRisk(); 

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <ToastContainer />
        <Routes>
          <Route path="/signin" element={<Signin />} />
          <Route path="/" element={<Signin />} />
          <Route path="/tiara" element={<HomeRegistration />} />
          <Route path="/terms/:uuid" element={<Terms />} />
          <Route path="/termsComplete/:uuid" element={<TermsComplete />} />
          <Route path="/signin/:uuid" element={<Signin />} />
          <Route path="/signup/:uuid" element={<Signup />} />
          <Route
            path="/homeregistration/"
            element={
              <PrivateRoute
                element={
                  <HomeRegistration
                    statusForm={statusForm}
                    setStatusForm={setStatusForm}
                    statusUpload={statusUpload}
                    setStatusUpload={setStatusUpload}
                    setStatusRisk={setStatusRisk}
                    statusRisk={statusRisk}
                  />
                }
              />
            }
          />
          <Route
            path="/select-proposal/"
            element={
              <PrivateRoute
                element={
                  <SelectProposal
                    statusForm={statusForm}
                    setStatusForm={setStatusForm}
                    statusUpload={statusUpload}
                    setStatusUpload={setStatusUpload}
                    setStatusRisk={setStatusRisk}
                    statusRisk={statusRisk}
                  />
                }
              />
            }
          />
          
          <Route
            element={
              <PrivateRoute
                element={<Header saveQuestions={SaveQuestions} />}
                statusForm={statusForm}
              />
            }
          />
          <Route
          path="/form"
          element={<PrivateRoute element={<Form />} />}
        />
          <Route
            path="/forecast/"
            element={<PrivateRoute element={<Forecast />} />}
          />
          <Route
            path="/uploads/"
            element={<PrivateRoute element={<Uploads />} />}
          />
          
        </Routes>
      </Router>
    </QueryClientProvider>
  );
};

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <QueryClientProvider client={new QueryClient()}>
    <ErrorProvider>
    <PartnersProvider>
      <StatusRiskProvider>
      <StatusFormProvider>
        <StatusUploadProvider>
          <App />
          </StatusUploadProvider>
      </StatusFormProvider>
      </StatusRiskProvider>
      </PartnersProvider>
      </ErrorProvider>
     
    </QueryClientProvider>
    
  </React.StrictMode>
);

import { Breadcrumbs } from "@mui/material";
import { useEffect, useState } from "react";
import { FaWpforms } from "react-icons/fa";
import { FaEnvelopeOpenText, FaMoneyBillTrendUp } from "react-icons/fa6";
import { FiUpload } from "react-icons/fi";
import { IoHome } from "react-icons/io5";
import "./style.css";

const BreadCrumbs = () => {
  const [cnpjSelected, setCnpjSelected] = useState("");
  const [lastPath, setLastPath] = useState("");

  useEffect(() => {
    const analysis = localStorage.getItem("analysis");
    if (analysis !== null && analysis !== undefined) {
      const name = JSON.parse(analysis).dataCnpj.name;
      setCnpjSelected(name);
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/form")
      setLastPath({ title: "Formulário", path: "/form", icon: <FaWpforms /> });

    if (window.location.pathname === "/uploads")
      setLastPath({ title: "Uploads", path: "/uploads", icon: <FiUpload /> });

    if (window.location.pathname === "/forecast")
      setLastPath({
        title: "Forecast",
        path: "/forecast",
        icon: <FaMoneyBillTrendUp />,
      });
  }, []);

  const getSelectedClass = (path) =>
    window.location.pathname === path &&
    window.location.pathname != "/select-proposal"
      ? "selected"
      : "";

  return (
    <Breadcrumbs className="breadcrumbs" separator="/" color="#ffffff80">
      <a
        href="/select-proposal"
        className={getSelectedClass("/select-proposal")}
        onClick={() => {
          localStorage.removeItem("dataCnpjId");
        }}
      >
        <IoHome />
        Propostas
      </a>
      {window.location.pathname !== "/select-proposal" && (
        <a
          href="/homeregistration"
          className={getSelectedClass("/homeregistration")}
        >
          <FaEnvelopeOpenText />
          {cnpjSelected}
        </a>
      )}
      {window.location.pathname !== "/select-proposal" &&
        window.location.pathname !== "/homeregistration" && (
          <a href={lastPath.path} className="selected">
            {lastPath.icon}
            {lastPath.title}
          </a>
        )}
    </Breadcrumbs>
  );
};

export default BreadCrumbs;

import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import * as React from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../../src/assets/entrepay-logo.svg";
import CenteredCircularProgress from "../../components/CenteredCircularProgress";
import axios from "axios";

require("../tokenRenewal");

const HeaderLogo = styled("img")({});

const ButtonStyled = styled(Button)({
  marginTop: "29px",
  backgroundColor: "#e5007e",
  color: "white",
  padding: "10px",
  borderRadius: "8px",
  width: "100%",
  marginBottom: "8px",
  "&:hover": {
    backgroundColor: "#fa2f9f",
  },
});

const LogoStyled = styled("div")({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  padding: "40px",
  flexDirection: "column",
});

const TextFieldWrapper = styled(TextField)`
  width: 100%;
  fieldset {
    border-radius: 8px;
  }
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function SignIn(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [forgotPassword, setForgotPassword] = useState(true);
  const [disabledButton, setDisabledButton] = useState(false);
  const [loading, setLoading] = useState(false);

  // React.useEffect(() => {
  //   setLoading(true);
  //   const checkLogin = auth.onAuthStateChanged((user) => {
  //     if (user) {
  //       navigate("/homeregistration/");
  //     }
  //     setLoading(false);
  //   });

  //   return () => checkLogin();
  // }, [navigate]);

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const onChangeForgotEmail = (event) => {
    setForgotEmail(event.target.value);
  };
  const onChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const onClickSignIn = async (event) => {
    const auth = getAuth();
    const tenantId = process.env.REACT_APP_TENANT_ID;
    auth.tenantId = tenantId;

    try {
      const user = await signInWithEmailAndPassword(auth, email, password);

      localStorage.setItem("email", email);
      localStorage.setItem("user", user);

      if (localStorage.getItem("lastPage") === "/legalform") {
        navigate("/legalform/");
      } else {
        navigate("/homeregistration/");
      }

      setDisabledButton(true);
    } catch (error) {
      console.error(error);
      const errorCode = error.code;
      if (errorCode === "auth/user-not-found") {
        setDisabledButton(false);
        return toast.error("email ou password incorreto ");
      }
      toast.error("Erro ao tentar efetuar login, tente novamente");
    }
  };

  const onClickResetPassword = async (event) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/onboarding/reset-password`,
        { email: forgotEmail },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("E-mail enviado com sucesso.");
      handleClose();
      setForgotEmail("");
      navigate("/");
    } catch (error) {
      console.error("Erro:", error.response || error.message || error);
      toast.error("Erro ao enviar e-mail");
    }
  };

  let params = useParams();

  let { uuid } = params;

  const getUserSignIn = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_NO_TOKEN_URL + "/terms/" + uuid
      );
      setEmail(response.data.analysis?.email);
      setEmailDisabled(true);
      // //console.log(response);
    } catch (error) {
      console.error(error);
      navigate("/signin/");
    }
  };
  React.useEffect(() => {
    if (uuid !== null && uuid !== undefined) {
      getUserSignIn();
    } else {
      navigate("/signin");
    }
  }, [uuid, navigate]);

  if (loading) {
    return <CenteredCircularProgress />;
  }

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item>
        <Card
          sx={{
            marginTop: 10,
            marginBottom: 10,
            boxShadow: "0 0 1em #DCDCDC",
            padding: 10,
          }}
        >
          <LogoStyled>
            <HeaderLogo className="logo" src={logo} />
            <Typography
              sx={{
                marginBottom: 3,
                marginTop: 3,
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              Faça seu login
            </Typography>
          </LogoStyled>

          <Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 14 }}>Email</Typography>
              <TextFieldWrapper
                id="outlined-basic"
                label=""
                variant="outlined"
                size="small"
                placeholder="Insira um email"
                value={email}
                disabled={emailDisabled}
                onChange={onChangeEmail}
              />
            </Grid>
            <Grid item>
              <Typography sx={{ marginTop: 3, fontSize: 14 }}>Senha</Typography>
              <TextFieldWrapper
                id="outlined-basic"
                label=""
                variant="outlined"
                size="small"
                type="password"
                placeholder="Insira uma senha"
                value={password}
                onChange={onChangePassword}
              />
            </Grid>
            <Grid item>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <ButtonStyled
                  onClick={onClickSignIn}
                  disabled={disabledButton}
                  size="small"
                >
                  Fazer login
                </ButtonStyled>
                <div></div>
                <a onClick={handleOpen} href="#void">
                  Esqueci minha senha
                </a>
              </div>
            </Grid>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <>
                {forgotPassword === true && (
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{ marginBotton: 3, fontSize: 18 }}
                    >
                      Insira seu email para recuperar sua senha
                    </Typography>
                    <TextFieldWrapper
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Insira seu email"
                      value={forgotEmail}
                      onChange={onChangeForgotEmail}
                    />
                    <ButtonStyled onClick={onClickResetPassword} size="small">
                      Recuperar senha
                    </ButtonStyled>
                  </Box>
                )}
                {forgotPassword === false && (
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{ marginBotton: 3, fontSize: 18 }}
                    >
                      As instruções de recuperação de senha foram enviadas para
                      o email informado.
                    </Typography>
                  </Box>
                )}
              </>
            </Modal>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
export default SignIn;

import React, { createContext, useContext, useState, useEffect } from "react";
import {
  getStatusUpload,
  setStatusUpload as persistStatusUpload,
} from "../utils/statusUpload";

const StatusUploadContext = createContext();

export const useStatusUpload = () => useContext(StatusUploadContext);

export const StatusUploadProvider = ({ children }) => {
  const [statusUpload, setStatusUploadState] = useState(getStatusUpload);

  const setStatusUpload = (statusUpload) => {
    setStatusUploadState(statusUpload);
    persistStatusUpload(statusUpload);
  };

  return (
    <StatusUploadContext.Provider value={{ statusUpload, setStatusUpload }}>
      {children}
    </StatusUploadContext.Provider>
  );
};
